<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <ion-buttons slot="end">
          <ion-button @click="dismissModal">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content p-3">
      <ion-list class="bg-transparent">
        <ion-radio-group v-model="selectedFolder" mode="md">
          <ion-item lines="none" class="mb-2 clickable-item">
            <ion-label class="mx-2 label-name">All Creations</ion-label>
            <ion-radio class="clickable-item-hov" value="" style="margin-inline-end: 5px"></ion-radio>
          </ion-item>
          <ion-item lines="none" class="mb-2 clickable-item" v-for="(folder, index) of folders" :key="index">
            <ion-label class="mx-2 label-name">{{ folder.name }}</ion-label>
            <ion-radio class="clickable-item-hov" :value="folder.id" style="margin-inline-end: 5px"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-end mx-3">
          <ion-button @click="onSubmit">Submit</ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },

  folders: {
    type: Array,
    default: () => [],
  },
  folder: {
    type: String,
  },
});
const folder = toRef(props, 'folder');
const selectedFolder: any = ref('');
watch(folder, () => {
  selectedFolder.value = folder.value;
});

const emits = defineEmits(['close', 'submit']);

const dismissModal = () => {
  emits('close');
};

const onSubmit = () => {
  emits('submit', selectedFolder.value);
};
</script>
<style lang="sass" scoped>
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 60vh
  overflow: auto

.modal-big
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
</style>
