import { authStore } from '@/shared/pinia-store/auth';
import { formatFromNowInMilliseconds } from '@/shared/utils/dateTime';

export const useWelcomeDiscountTime = () => {
  const discountEndsAt = () => {
    // returns nothing if past the date
    const { user } = authStore();
    const d1 = new Date(user.value.date_joined);
    const ds2 = '2024-03-15T12:00:00.000000Z';
    const d2 = new Date(ds2);
    const dateToUse = d1 > d2 ? user.value.date_joined : ds2;
    const discountEndDate = formatFromNowInMilliseconds(dateToUse, true) + 86400 * 3 * 1000;

    return discountEndDate > 0 ? discountEndDate : 0;
  };

  return { discountEndsAt };
};
