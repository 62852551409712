<template>
  <div class="d-flex" :class="isVisible ? 'mt-3 flex-wrap' : 'flex-column footer-content'">
    <router-link class="wdth btn-wrp" to="/terms">
      <ion-button class="footer-btn flex-grow-1">
        <inline-svg class="mr-1" :class="{ svg: !isVisible }" src="/icons/tos.svg" />
        <span v-if="isVisible">Terms of Service</span>
      </ion-button>
    </router-link>

    <a
      target="_blank"
      class="wdth btn-wrp position-relative"
      href="https://docs.google.com/document/d/1xSdAdkRj7n8BfJuz0KKPiM2IJTi8MiAVePruZqr4Gag/edit#heading=h.94u1oilstqx5"
    >
      <!-- <div class="soon">Soon</div> -->
      <ion-button class="footer-btn flex-grow-1">
        <inline-svg class="mr-1" :class="{ svg: !isVisible }" src="/icons/ruler.svg" />
        <span v-if="isVisible">Content Guidelines</span>
      </ion-button>
    </a>

    <router-link class="wdth btn-wrp" to="/privacy">
      <ion-button class="footer-btn flex-grow-1">
        <inline-svg class="mr-1" :class="{ svg: !isVisible }" src="/icons/privacy.svg" />
        <span v-if="isVisible"> Privacy Policy</span>
      </ion-button>
    </router-link>
    <a class="wdth btn-wrp" href="https://characterhub.com/blog" target="_blank">
      <ion-button class="footer-btn flex-grow-1">
        <inline-svg class="mr-1" :class="{ svg: !isVisible }" src="/icons/blog.svg" />
        <span v-if="isVisible"> Blog</span>
      </ion-button>
    </a>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  isVisible: { type: Boolean },
});
const isVisible = toRef(props, 'isVisible');

const getBtnSize = computed(() => {
  return isVisible.value ? 'calc(50% - 4px)' : 'calc(100% - 8px)';
});
</script>

<style lang="sass" scoped>
.footer-content
  padding-bottom:72px
  margin-top:6px !important
.svg
  width:24px
  height:24px
.soon
  font-size: 9px
  color: #fff
  border-radius: 10px
  padding: 2px 4px
  position: absolute
  background: rgba(255, 56, 100, 1)
  z-index: 1
  right: 5px
.wdth
  width: v-bind(getBtnSize)
.footer-btn
  color: rgba(84, 115, 149, 1)
  --ion-color-primary: transparent !important
  font-size: 12px
  font-weight: bold

.btn-wrp
  display: flex
  justify-content: center
  &[disabled]
    pointer-events: none
</style>
